import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, orderByChild, query, equalTo } from "firebase/database";
import Swal from 'sweetalert2'
import app from "../firebaseConfig";
import { useLocation, useParams, useNavigate, Link  } from "react-router-dom";


export default function Table({data}) {


  const [pageViews, setPageViews] = useState(0);

  const [email, setEmail] = useState('');
  const history = useNavigate();
  const five = email.substr(email.length -5);
  const handleSubmit = (e) => {
   
    e.preventDefault();
       const db = getDatabase(app);
       const dbRef = ref(db, "USERS");
 
       const qry = query(dbRef, orderByChild("phone_number"), equalTo(five));
       get(qry).then((snapshot) => {
     if (snapshot.exists()) {
         
   
       history("/ptable");
        
           } else {
             Swal.fire({
               title: "NO SUBSCRIPTION FOUND/SUBSCRIPTION EXPIRED ",
               text: "Please subscribe to watch all Live Football matches",
               imageUrl: "https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png",
             imageWidth: 100,
           imageHeight: 100,
           imageAlt: "Custom image",
               showCancelButton: false,
               confirmButtonColor: 'red',
               confirmButtonText: 'CLICK TO SUBSCRIBE'
             }).then((result) => {
               if (result.isConfirmed) {
                 window.location.href = "https://bettystore.store/live-football-tv-hd/"
               }
             })
        
           }
           });

          }

  useEffect(() => {
    const storedPageViews = localStorage.getItem('pageViews');

    if (storedPageViews) {
      setPageViews(parseInt(storedPageViews, 10));
    }

    setPageViews((prevPageViews) => {
      const newPageViews = prevPageViews + 1;
      localStorage.setItem('pageViews', newPageViews.toString());
      return newPageViews;
    });
  }, []);
 
  const visits = Number(localStorage.getItem('visitCount')) ||0;
  localStorage.setItem('visitCount', visits + 1);

 
  return (
    <div>
      {(() => {
        if (pageViews <= 20) {
          return (
            <>
            <div class="flex flex-col container max-w-md mt-10 mx-auto w-full items-right justify-left bg-white dark:bg-gray-800 rounded-lg shadow">
           {data.map((fixture) => (
                   <Link to={`/fixture/${fixture.user}`} key={fixture.user}>
                     <ul class="flex flex-col divide-y w-full">
             <li class="flex flex-row">
               <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                 <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                   <a href="#" class="block relative">
                     <img alt="profil" src={fixture.webFormatUrl}class="mx-auto object-cover rounded-full h-15 w-15" />
                   </a>
                 </div>
                 <div class="flex-1 pl-1 mr-16">
                   <div class="text-sky-800 dark:text-sky-800 text-xs font-sans	font-bold	 uppercase ms-px" >{fixture.user}</div>
                   <div class="text-gray-600 dark:text-gray-200 text-xs normal-case my-2">{fixture.type}</div>
                 </div>
                 <div class="text-red-400 dark:text-red-400 text-xs  font-bold ">{fixture.status}</div>
               </div>
             </li>
            </ul>
           </Link>  
           ))}
            </div>
           </>
          )
        } else {
          return (
            <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
          
            <form 
            
            
            className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0"
            onSubmit={handleSubmit}>
        <div className="md:w-1/3 max-w-sm justify-items-center"
        >
        
          <img
            src="https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png"
            alt="Sample image" />
            
        </div>
        <div className="md:w-96 max-w-sm">
        <label className="mr-1 mb-px text-base ">Already a subscriber? Sign in with mobile number</label>
          <div className="text-center md:text-left">
          
          </div>
        
          <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded" type="text" placeholder="Phone Number" value={email}
          onChange={(e) => setEmail(e.target.value)} />
         
          <div className="text-center md:text-left">
            <button
               class="nav-button hover:drop-shadow-lg] flex w-full items-center justify-center rounded-full border border-[#072C48] bg-[#072C48] bg-gradient-to-tr from-[#0767AC] to-[#0767AC]/70 px-7 py-2.5 text-white font-bold text-white-800 ring-lime-600 ring-offset-2 ring-offset-white-700 drop-shadow-[0px_1px_2px_rgb(0,0,0,0.3)] active:ring-1 mt-4">
            
               Log in</button>
          </div>
          <div className="mt-4 font-semibold text-base text-slate-500 text-center md:text-left">
            Not yet a Subscriber? <a className="text-red-600 hover:underline hover:underline-offset-4" href="https://bettystore.store/live-football-tv-hd/">Click to Subscribe</a>
          </div>


          <div className="mt-10 font-semibold text-base text-slate-500 text-center md:text-left justify-items-end">
            Need Help? <a className="text-red-600 hover:underline hover:underline-offset-4" href="https://api.whatsapp.com/send?phone=2347038872360">Click to Chat Support</a>
          </div>
         
          <div class="flex flex-wrap justify-center">
          
            
        </div>
        </div>
        
        </form>
      </section>
          )
        }
      })()}
    </div>
  )

}